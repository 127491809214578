.localities_main {
  background: #FFF6F8;
  padding: 80px 0;
}
.section_row {
  padding: 100px 0;
}
.localities_card {
  position: relative;
  width: 100%;
  height: 300px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}
.localities_card .img {
  height: 100%;
  overflow: hidden;
}
.localities_card .img img {
  height: 100% !important;
  object-fit: cover;
  border-radius: 23px;
  width: 100%;
}
.localities_card_overlay {
  position: absolute;
  width: 100%;
  border-radius: 20px;
  bottom: 0;
  height: 100%;
  background: linear-gradient(to top, black, transparent);
}
.city_overlay_text h3 {
  color: #fff;
  font-size: 16px;
}
.localities_card_overlay1 {
  background: linear-gradient(
    0deg,
    #f0f7ff 43.75%,
    rgba(238, 240, 245, 0) 100%
  );
}
.localities_card_overlay2 {
  background: linear-gradient(
    0deg,
    #ffe7ce 43.75%,
    rgba(238, 240, 245, 0) 100%
  );
}
.localities_card_overlay3,
.localities_card_overlay4 {
  background: linear-gradient(
    0deg,
    #cececd 43.75%,
    rgba(238, 240, 245, 0) 100%
  );
}
.overlay_text {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 0 10px;
}
.h40 {
  height: 40%;
}

@media only screen and (max-width: 768px) {
  .section_row {
    padding: 50px 0;
  }
  .localities_card {
    width: 100%;
    height: auto;
  }
  .overlay_text p {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .localities_card .img img {
    border-radius: 10px;
  }
  .localities_card_overlay {
    border-radius: 10px;
  }
  .localities_card .img {
    border-radius: 10px;
  }
  .city_overlay_text h3 {
    font-size: 13px;
  }
  .localities_main {
    padding: 25px 0 40px;
  }
  .featured_star i {
    font-size: 13px;
  }
  .featured_star span {
    font-size: 13px;
  }
  .featured_address {
    font-size: 12px;
    margin-bottom: 0;
  }
  .localities_card {
    height: 200px;
  }
  .flex_wrp {
    flex-wrap: wrap;
  }
  .h160 {
    height: 160px;
  }
}
@media only screen and (max-width: 1400px) {
  .localities_card {
    width: 100%;
  }
}
