.filter_nav_main {
  padding: 18px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.filter_nav_main::-webkit-scrollbar {
  display: none;
}
.project_type_main {
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  z-index: 99;
  top: 87px;
}
.price_value_text {
  font-size: 29px;
  font-weight: 600;
  color: #ff385c;
  text-align: center;
}
.filter_modal_btn {
  width: 100%;
  background-color: #ff385c;
  color: #fff;
}
.range-slider input[type="range"] {
  display: none !important;
}
.rc-slider-track {
  background-color: #f9d1d5 !important;
}
.rc-slider-rail {
  background-color: #ffebed !important;
}
.price_main_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 30px;
}
.min_max {
  font-size: 13px;
  color: rgba(34, 34, 34, 0.5);
}
.price_slider_value_box {
  border: 1px solid rgba(34, 34, 34, 0.2);
  padding: 6px 10px;
  width: 40%;
  border-radius: 10px;
}
.value_text {
  color: #222;
  font-size: 15px;
}
.rc-slider-handle {
  border: solid 2px #ffd1d6 !important;
}
.rc-slider-handle:hover {
  background-color: #ff385c !important;
  border-color: #ff385c !important;
}
.rc-slider-handle:active {
  border-color: #ff385c !important;
  box-shadow: 0 0 5px #ff385c !important;
}
.filter_modal_btn:hover {
  border: 1px solid #ff385c;
  color: #ff385c;
}
.filter_nav_item {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.filter_nav_item img {
  width: 30px;
  height: 30px;
}
.filter_nav_item p {
  font-size: 12px;
  color: #222;
  margin-bottom: 0;
}
.filter_icon {
  width: 20px !important;
  height: 17px !important;
  margin-right: 6px;
}
.filter_button {
  border: 1px solid #222;
  font-size: 15px;
  font-weight: 500;
  color: #222;
}
.filter_button:hover {
  border: 1px solid #222;
}
.filter_label {
  margin-bottom: 10px;
  color: #ff385c;
  font-size: 15px;
  font-weight: 600;
}
.modal_filter_main {
  background-color: #fff;
  width: 555px;
  padding: 0 30px 30px;
  border-radius: 20px;
}
.filter_btn_grp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear_filter {
  background: rgba(34, 34, 34, 0.1);
}
.clear_filter:hover {
  background: rgba(34, 34, 34, 0.3) !important;
}

@media only screen and (max-width: 768px) {
  .project_type_main {
    position: initial;
    box-shadow: none;
    background: transparent;
  }
  .filter_nav_item {
    min-width: 98px;
  }
  .filter_nav_item:nth-child(1) {
    min-width: 52px;
    margin-right: 12px;
  }
  .filter_nav_main {
    padding: 10px 0;
  }
  .filter_nav_item p {
    font-size: 10px;
  }
}
