.builders_row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.builder_logo_box {
    width: 162px;
}
.builder_logo_box {
    min-width: 129px;
}
.builder_logo_box:not(:last-child) {
    margin-right: 62px;
}
.builder_logo_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.builders_row::-webkit-scrollbar {
    display: none;
}
.builder_slider_container {
    padding: 100px 0;
}
.builder_container .rec-arrow-left {
    z-index: 99;
    left: -41px;
}
.other_builders_top .rec-arrow-left {
    z-index: 99;
    left: -41px;
}
.other_builders_top .rec-arrow-right {
    right: -7px;
}
.builder_container .rec-arrow-right {
    right: -7px;
}
.builder_container {
    padding-top: 100px;
    padding-bottom: 100px;
}
.builder_card {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07) !important;
    border: none;
    border-radius: 20px;
    width: 100%;
}
.builder_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
}
.builder_img img {
    width: 100px;
}
.builder_card_text {
    font-weight: 800;
    color: #222;
}

@media only screen and (max-width: 768px) {
    .builder_container {
        padding: 0 10px;
    }
    .builder_card {
        width: 100%;
    }
    .builder_img {
        height: 100px;
    }
    .builder_img img {
        width: 73px;
    }
    .builder_slider_container {
        padding: 50px 10px;
    }
    .builder_logo_box:not(:last-child) {
        margin-right: 30px;
    }
    .builder_logo_box {
        min-width: 78px;
    }
}
@media only screen and (max-width: 1400px) {
    .carousel-wrapper-home-builder .dYgkhw {
        width: 97% !important;
    }
}
