@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap");

* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}

.m60 {
  margin-top: 60px;
}

h1 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  color: #222;
}
.heading {
  font-size: 26px;
  font-weight: 600;
  color: #222;
}
.underline {
  position: relative;
}
.underline::after {
  content: "";
  width: 197px;
  height: 3px;
  background-color: #ff385c;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.error_validate {
  font-size: 13px;
  font-weight: 500;
  color: #ff385c;
  margin-bottom: 0;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
a {
  color: #222;
  text-decoration: none;
}

h3 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0 !important;
}
a {
  text-decoration: none;
}
h5 {
  font-size: 18px;
  font-weight: 600;
  color: #222;
  margin-bottom: 0;
}
h6 {
  font-size: 14px;
  font-weight: 600;
  color: #222;
  margin-bottom: 0;
}
p {
  font-weight: 500;
}
.mb30 {
  margin-bottom: 30px;
}
.divider_line {
  background-color: rgba(34, 34, 34, 0.5);
  margin-top: 30px;
}
.mt100 {
  margin-top: 100px;
}
.primary_color {
  color: #ff385c;
}
.desk_hide {
  display: none;
}
.font-semibold {
  font-weight: 600;
}
.text-justify {
  text-align: justify;
}
.carosal {
  flex-wrap: nowrap;
  overflow: scroll;
  position: relative;
  margin-top: 30px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.carosal::-webkit-scrollbar {
  display: none;
}
.scroll_icon {
  position: absolute;
  top: 44%;
  cursor: pointer;
  width: 16px;
  color: #ff385c !important;
  z-index: 1;
}
.scroll_icon2 {
  top: 50%;
}
.scroll_left {
  left: -27px;
}
.scroll_right {
  right: -17px;
}
.text-capitalize {
  text-transform: capitalize;
}
.top_localities {
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.scroll_icon3 {
  right: -25px;
}
.w95 {
  width: 95%;
}
.top_localities::-webkit-scrollbar {
  display: none;
}
.heading img {
  width: 27px;
  margin-bottom: 6px;
}
.featured_goal {
  width: 60px !important;
  bottom: -19px;
}
.icon_call {
  font-size: 23px;
}
@media only screen and (max-width: 768px) {
  .icon_call {
    font-size: 18px;
  }
  .mob_hide {
    display: none !important;
    margin: 0 !important;
  }
  h1 {
    font-size: 22px;
  }
  .mt100 {
    margin-top: 50px;
  }
  .heading {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  .desk_hide {
    display: block;
  }
  .m60 {
    margin-top: 30px;
  }
  h6,
  p {
    font-size: 13px;
  }
  h5 {
    font-size: 14px;
  }
  .carosal {
    margin-top: 15px;
  }
  .w95 {
    width: 99%;
  }
  .heading img {
    width: 20px;
    margin-bottom: 4px;
  }
  .featured_goal {
    width: 40px !important;
    bottom: -11px;
  }
  .text_black_mob {
    color: #222 !important;
  }
  .underline::after {
    width: 180px;
    height: 2px;
    bottom: -6px;
  }
  .filter_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter_box button {
    border: none;
    background-color: transparent;
  }
  .filter_box button img {
    width: 20px;
  }
  .filter_btn_grp button {
    font-size: 13px;
  }
}
