.city_banner_main {
  margin-top: 170px;
  padding-top: 52px;
  background-color: #fff;
  /* background: url("../media/citypage-banner.png"); */
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 77vh;
}

.cityad {
  padding: 40px;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
}

.cityad h2 {
  color: #fff;
  font-size: 26px;
  letter-spacing: 1px;
}

.ad-wrapper button {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 600;
}

.ad-wrapper .enq {
  margin-left: 20px;
  z-index: 1;
  bottom: 39px;
  left: 212px;
  position: absolute;
}

.cityad p {
  color: #fff;
  letter-spacing: 1px;
}

.cityad ul {
  padding-left: 25px;
}

.cityad ul li {
  color: #fff;
  position: relative;
  list-style-type: none;
}

.cityad ul li::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../media/ad-right-tick.svg");
  left: -28px;
  background-size: cover;
  top: 3px;
}

.cityPage_heading {
  text-align: center;
  position: absolute;
  top: 169px;
  width: 100%;
  color: white;
  padding: 0 10px;
}

.cityPage_heading span {
  text-transform: capitalize;
  font-size: 54px;
  line-height: 68px;
}

.city_localities {
  background: linear-gradient(0deg,
      #f4f2ff 43.75%,
      rgba(238, 240, 245, 0) 100%);
}

.no_space_text {
  font-size: 16px;
  font-weight: 600;
  color: rgba(34, 34, 34, 0.5);
}

.city_select_form .css-b62m3t-container {
  position: absolute !important;
  box-sizing: border-box;
  top: 267px;
}

.search_location {
  width: 287px;
  margin: 30px auto 0;
  position: relative;
}

.search_location .css-13cymwt-control {
  border-radius: 29px !important;
  padding: 6px 7px !important;
}

.search_location .css-t3ipsp-control {
  border-radius: 29px !important;
  padding: 6px 0 !important;
  border-color: #ff7b93 !important;
  box-shadow: 0 0 0 1px #ff7b93 !important;
}

.video_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #0000006e;
}

.city_video_box {
  width: 100%;
  height: 60vh;
  position: relative;
  top: 87px;
}

.city_video_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.city_bg_height {
  height: 100%;
}

.city_container_main2 {
  padding-top: 50px;
}

.city_container_main {
  padding-top: 50px;
}

.btn_black {
  color: black !important;
  border: 1px solid black !important;
}

.card_location {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 1px;
}

.card_price {
  color: #fff;
  margin-bottom: 0;
}

.css-1nmdiq5-menu ul li {
  min-width: 250px !important;
}

.localities_homecard {
  border-radius: 20px;
  width: 293px;
}

.localities_homecard .carousel-control-next-icon,
.localities_homecard .carousel-control-prev-icon {
  border-radius: 50% !important;
  background-color: #fff !important;
  background-size: 50% !important;
  transition: 0.3s all ease-in-out !important;
  position: relative;
}
.localities_homecard .carousel-control-prev-icon:after {
  content: "\f104";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}
.localities_homecard .carousel-control-next-icon:after {
  content: "\f105";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}

.localities_homecard .carousel-indicators{
  display: none !important; 
}


@media only screen and (max-width: 768px) {

  .localities_homecard {
    width: 100%;
  }


  .city_banner_main {
    margin-top: 50px;
    height: 50vh;
  }

  .search_location .css-t3ipsp-control {
    padding: 3px 0 !important;
  }

  .city_video_box {
    height: 36vh;
    top: 52px;
    background: rgba(255, 245, 246, 0.5);
  }

  .city_container_main {
    padding-top: 123px;
  }

  .ptCity {
    padding-top: 0;
  }

  .p50 {
    padding-top: 50px;
  }

  .search_location {
    width: 86%;
    position: absolute;
    box-sizing: border-box;
    top: 225px;
  }

  .city_select_form .css-b62m3t-container {
    position: absolute !important;
    top: 130px;
  }

  .search_location .css-13cymwt-control {
    border-radius: 21px !important;
    padding: 3px 0 !important;
  }

  .cityPage_heading span {
    font-size: 30px;
    line-height: 40px;
    color: #fff;
  }

  .city_row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 0px;
  }

  .city_row::-webkit-scrollbar {
    display: none;
  }

  .city_overlay_text h3 {
    font-size: 14px;
  }

  .city_overlay_text {
    margin-bottom: 10px !important;
  }

  .cityPage_heading {
    position: absolute;
    top: 77px;
    color: white;
    width: 100%;
  }

  .cityad {
    padding: 13px;
    border-radius: 10px;
    width: 100%;
  }

  .proAd {
    width: 93%;
  }

  .cityad h2 {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .cityad p {
    letter-spacing: 1px;
    margin-bottom: 7px;
  }

  .cityad ul {
    padding-left: 19px;
  }

  .cityad ul li {
    font-size: 13px;
  }

  .cityad ul li::before {
    width: 15px;
    height: 15px;
    left: -22px;
    top: 1px;
  }

  .ad-wrapper button {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .ad-wrapper .enq {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 186px;
  }
}