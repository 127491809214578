.visible_list {
    display: flex;
    padding-bottom: 5px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-bottom: 20px;
    list-style-type: none;
    padding-left: 0;
}
.visible_list::-webkit-scrollbar {
    display: none;
}
.visible_list li {
    margin-right: 6px;
    cursor: pointer;
}
.visible_list li a {
    background: #fff;
    border-radius: 3px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #9e9e9e;
    border: 1px solid #d4d4d4;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
.visible_list li a:hover {
    border: 1px solid #ff385c;
    color: #ff385c;
}
.active_location .active {
    border: 1px solid #ff385c !important;
    color: #ff385c !important;
}