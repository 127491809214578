.accordion-button::after {
    background-image: url("../../media//down-arrow.png") !important;
}
.accordion-button:not(.collapsed) {
    background-color: initial !important;
}
.accordion-button:focus {
    border-color: transparent !important;
    box-shadow: none;
}
.accordion-button {
    font-weight: 600;
}
.faq_img_box img {
    width: 100px;
}
.faq_container {
    margin-bottom: 60px;
}
.mb60 {
    margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
    .mb60 {
        margin-bottom: 30px;
    }
    .accordion-button {
        font-size: 13px;
        --bs-accordion-btn-padding-x: 0.25rem;
    }
    .accordion-button::after {
        --bs-accordion-btn-icon-width: 14px;
    }
    .accordion-body {
        font-size: 12px;
        padding: 3px;
    }
    .faq_container {
        margin-bottom: 30px;
    }
}