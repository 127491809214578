.footer_main_box {
  background-color: rgba(255, 245, 246, 0.5);
  padding: 50px 0;
  /* margin-top: 60px; */
}
.footer_text {
  font-size: 15px;
  margin: 8px 0;
  color: rgba(34, 34, 34, 0.5);
  text-decoration: none;
  font-weight: 500;
}
.footer_link {
  color: #222;
  font-size: 17px;
  font-weight: 500;
}
.footer_heading {
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  width: 64%;
  padding-bottom: 6px;
}
.footer_col {
  padding-left: 60px;
}
.footer_heading h6 {
  font-size: 16px;
}
.bottom_footer p {
  margin-bottom: 0;
  font-size: 12px;
  color: #222;
  font-weight: 500;
}
.bottom_footer {
  padding: 10px 0;
}
.footer_title {
  font-size: 18px;
  font-weight: 600;
  color: #222;
}
.footer_content p {
  font-size: 14px;
    color: #222;
}
.footer_content ol {
  padding-left: 15px;
}
.footer_content ol li {
  font-size: 14px;
  color: #222;
}
.footer_content h2 {
  font-size: 16px;
    font-weight: 600;
    color: #222;
}
.footer_content h4 {
  font-size: 15px;
    font-weight: 600;
    color: #222;
}
.footer_content h3 {
  font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px !important;
    color: #222;
}
.footer_content_main {
  background: #fafafa;
  padding: 50px 0;
}
@media only screen and (max-width: 768px) {
  .footer_text {
    font-size: 13px;
  }
  .footer_main_box {
    padding: 25px 0;
  }
  .footer_link {
    font-size: 13px;
  }
  .bottom_footer p {
    font-size: 10px;
  }
  .footer_main_box {
    margin-top: 40px;
  }
  .footer_title {
    font-size: 16px;
  }
  .footer_content p {
    font-size: 12px;
  }
  .footer_content ol li {
    font-size: 12px;
  }
  .footer_content h2, .footer_content h3 {
    font-size: 15px;
  }
  .footer_content h4 {
    font-size: 14px;
  }
  .footer_content_main {
    padding: 30px 0;
  }
}
