.page_not_found img {
    width: 400px;
}
.page_not_found {
    text-align: center;
    padding: 50px 0;
}
@media only screen and (max-width: 768px) {
    .page_not_found img {
        width: 345px;
    }
}