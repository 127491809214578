.home_banner_main {
  background: rgba(255, 245, 246, 0.5);
  padding-top: 82px;
  margin-top: 57px;
}
.searchForm {
  width: 566px;
  margin: 30px auto 0;
  position: relative;
}
.search_img_box {
  position: absolute;
  right: 22px;
  width: 38px;
  height: 38px;
  background-color: #fff5f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
}
.searchForm input {
  border-radius: 60px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07);
  padding: 14px 16px;
}
.brand_logo {
  text-align: center;
}
.brand_logo img {
  width: 279px;
}
.mob_box {
  width: 304px;
  margin: 60px auto 0;
  position: relative;
  z-index: 1;
}
.my_container {
  position: relative;
}

.city-div{
  position: absolute;
  top:0;
  left: 0;
  width: auto;

}
  /* .circle_box {
  position: absolute;
  overflow: hidden;
  border-radius: 50%;
}
.circle_box1 {
  width: 144px;
  height: 144px;
  top: 65px;
  left: 344px;
}
.circle_box img {
  height: 100% !important;
}
.circle_box2 {
  width: 100px;
  height: 100px;
  top: 68px;
  left: 141px;
}
.circle_box3 {
  width: 80px;
  height: 80px;
  top: 204px;
  left: 0px;
}
.circle_box4 {
  width: 40px;
  height: 40px;
  top: 208px;
  left: 138px;
}
.circle_box5 {
  width: 70px;
  height: 70px;
  left: 173px;
  bottom: 49px;
}
.circle_box6 {
  width: 124px;
  height: 124px;
  bottom: 15px;
  left: 305px;
}
.circle_box7 {
  width: 30px;
  height: 30px;
  top: 72px;
  left: 275px;
  background-color: #fff0f1;
}
.circle_box8 {
  width: 30px;
  height: 30px;
  top: 159px;
  left: 85px;
  background-color: #fff0f1;
}
.circle_box9 {
  width: 70px;
  height: 70px;
  top: 171px;
  left: 236px;
  background-color: #fff0f1;
}
.circle_box10 {
  width: 40px;
  height: 40px;
  left: 99px;
  background-color: #fff0f1;
  bottom: 59px;
}
.circle_box11 {
  width: 30px;
  height: 30px;
  left: 439px;
  background-color: #fff0f1;
  bottom: 12px;
}
.circle_box12 {
  width: 45px;
  height: 45px;
  left: 382px;
  background-color: #fff0f1;
  bottom: 151px;
}
.circle_box13 {
  width: 60px;
  height: 60px;
  left: 499px;
  background-color: #fff0f1;
  bottom: 108px;
  z-index: -1;
}
.circle_box14 {
  width: 110px;
  height: 110px;
  top: 70px;
  right: 367px;
}
.circle_box15 {
  width: 40px;
  height: 40px;
  right: 240px;
  top: 93px;
}
.circle_box16 {
  width: 125px;
  height: 125px;
  right: 20px;
  top: 107px;
}
.circle_box17 {
  width: 85px;
  height: 85px;
  right: 233px;
  bottom: 178px;
}
.circle_box18 {
  width: 143px;
  height: 143px;
  right: 234px;
  bottom: 0px;
}
.circle_box19 {
  width: 70px;
  height: 70px;
  right: 99px;
  bottom: 71px;
}
.circle_box20 {
  width: 33px;
  height: 33px;
  background: #fff0f1;
  right: 191px;
  top: 116px;
}
.circle_box21 {
  width: 60px;
  height: 60px;
  background: #fff0f1;
  right: 402px;
  top: 200px;
}
.circle_box22 {
  width: 100px;
  height: 100px;
  background: #fff0f1;
  right: 486px;
  z-index: -1;
  bottom: 30px;
}
.circle_box23 {
  width: 50px;
  height: 50px;
  background: #fff0f1;
  bottom: 152px;
  right: 163px;
}
.react-tooltip {
  z-index: 99;
} */

.time {
  position: absolute;
  top: 23px;
  left: 45px;
  font-size: 14px;
  font-weight: 600;
  z-index: 10;
}
.mob_box .carousel-container {
  position: absolute;
  top: 10px;
  left: 13px;
  width: 92%;
}
.mob_box .img_cards {
  height: 330px;
  border-radius: 0;
  overflow: hidden;
}
.img_covers {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.mob_box .carousel-inner {
  border-radius: 47px 47px 0 0;
}
.mob_box .carousel-indicators {
  bottom: 77px;
}
.phone_camera {
  position: absolute;
  width: 73%;
  top: 10px;
  right: 18px;
  z-index: 10;
}
.phone_text {
  margin-left: 10px;
  margin-top: 5px;
}
.phone_text p:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
  color: #222;
  margin-bottom: 0;
}
.phone_text p:nth-child(2) {
  margin-bottom: 0;
  font-size: 14px;
}
.phone_text p:nth-child(3) {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 12px;
}
.builder_tooltip {
  background-color: #515050 !important;
  padding: 4px 6px !important;
  font-size: 14px !important;
}

@media only screen and (max-width: 600px) {
  .home_banner_main {
    margin-top: 0;
    padding-top: 109px;
    height: 392px;
  }
  .brand_logo img {
    width: 160px;
  }
  .home_btn_box {
    width: 100%;
    position: absolute;
    top: 218px;
    text-align: center;
  }
  .home_btn_box .modal_form_btn {
    width: 86%;
    border-radius: 20px;
    padding: 10px;
  }
  .brand_logo {
    position: absolute;
    width: 100%;
    top: 30px;
    z-index: 99;
  }
  .home_select_form .css-b62m3t-container {
    top: 120px !important;
  }
  .home_city_box {
    position: relative;
    overflow: hidden;
  }
  .home_city_box img {
    width: 100%;
    border-radius: 10px;
  }
  .homeStars {
    position: absolute;
    width: 40px !important;
    left: 80px;
    top: 75px;
    z-index: 1;
  }
  .circle_home {
    position: absolute;
    background-color: #fff;
    width: 118px;
    height: 118px;
    border-radius: 50%;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  /* .city_name_home {
    font-size: 16px;
    font-weight: 700;
    margin-top: 29px;
    margin-bottom: 0;
  } */
  .city_video_box2 {
    height: 580px !important;
  }
  /* .circle_home p {
    font-weight: 700;
    font-size: 14px;
  } */
  .new_heading {
    font-size: 19px;
    font-weight: 700;
    text-align: left;
  }
  .circle_project {
    width: 112px;
  }
  .circle_project img {
    width: 87px;
    height: 87px;
    border-radius: 50%;
  }
  .row_new {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    margin-top: 20px;
  }
  .row_new::-webkit-scrollbar {
    display: none;
  }
  .home_btns {
    background: linear-gradient(to right, #F857A6 0%, #FF5858 100%);
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    padding: 7px;
    color: #fff;
  }
  .gif img {
    position: absolute;
    left: -5px;
    width: 60px;
    height: 60px;
    top: -12px;
  }
}
@media only screen and (max-width: 1400px) {
  .my_container {
    max-width: 100% !important;
  }
}
