.heading {
  text-align: center;
}
.heading_text {
  text-align: center;
  color: #222;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .top_india {
    margin-top: 100px;
  }
  .top_india .row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .top_india .row::-webkit-scrollbar {
    display: none;
  }
}
