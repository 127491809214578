.img_card {
  height: 220px;
  overflow: hidden;
  border-radius: 14px;
}

.img_cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 14px;
}
.property_card {
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  /* width: 100%; */
}
.card_title_box {
  margin-bottom: 4px;
}
.star_image p {
  font-size: 16px;
  margin-bottom: 0;
}
.star_image p img {
  width: 16px;
  margin-right: 3px;
}
.star_image p i {
  font-size: 13px;
  margin-right: 3px;
  color: #ff385c;
}
.card_title {
  font-size: 15px;
  font-weight: 600;
  color: #222;
  white-space: normal;
}
.card_body {
  margin-top: 10px;
}
.carousel-indicators button {
  width: 9px !important;
  height: 9px !important;
  border-radius: 50%;
}
.carousel-indicators {
  margin-bottom: 0 !important;
  z-index: 1;
}
.carousel-inner {
  border-radius: 14px;
}
.carousel-item {
  transition: transform 0.5s ease;
}
.card_p {
  font-size: 14px;
  color: #222;
}
.card_footer {
  display: flex;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}
.card_footer div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.card_footer div:nth-child(2) {
  background-color: #fff5f6;
  border-radius: 0 10px 10px 0;
}
.card_footer div span {
  color: #ff385c;
}
.card_footer div span:nth-child(1) {
  font-weight: 600;
}
.card_icon {
  font-size: 18px;
  margin-bottom: 4px;
  margin-right: 10px;
}
.card_p span a {
  color: #ff385c;
}

/* homecard css */
.property_homecard {
  border-radius: 20px;
  width: 293px;
  /* margin-bottom: 30px; */
}
.homecard_p {
  margin-bottom: 4px;
}

.homecard_p span {
  color: #ff385c;
  font-weight: 600;
}
/* .property_card:not(:last-child) {
  margin-right: 29px;
} */
.property_homecard .carousel-control-next-icon,
.property_homecard .carousel-control-prev-icon {
  border-radius: 50% !important;
  background-color: #fff !important;
  background-size: 50% !important;
  transition: 0.3s all ease-in-out !important;
  position: relative;
}
.property_homecard .carousel-control-prev-icon:after {
  content: "\f104";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}
.property_homecard .carousel-control-next-icon:after {
  content: "\f105";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}
.carousel-container:hover .carousel-control-prev,
.carousel-container:hover .carousel-control-next {
  display: flex;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
  transition: 0.3s all ease-in-out;
}
/* .carousel-control-next-icon {
  background-image: url("https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1696935075543.png");
}
.carousel-control-prev-icon {
  background-image: url("https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1696935075543.png");
  rotate: 180deg;
} */

@media only screen and (max-width: 768px) {
  .property_homecard {
    width: 100%;
  }
  .card_title {
    font-size: 15px;
  }
  .card_p {
    font-size: 14px;
  }
  .star_image p {
    font-size: 12px;
  }
  .w96 {
    width: 96%;
  }
}

@media only screen and (max-width: 1400px) {
  .property_homecard {
    width: 100%;
  }
}
