.modal_form_main {
    background: #fff5f6;
    padding: 6px 20px 20px;
    width: 350px;
    border-radius: 10px;
    height: 360px;
    margin-top: 50px;
}

.cross_icon {
    display: flex;
    justify-content: flex-end;
}

.custom_form_check {
    margin-left: 12px;
}

.cross_icon button {
    background-color: initial;
    border: none;
    color: black;
    font-size: 24px;
    padding: 0;
    margin-top: -5px;
}

.form_heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.req_box {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0 !important;
}
.req_box span{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.req_box .contact_name{
    color: #ff5b79;
}
.policy {
    margin-top: 14px;
    padding: 0 1px;
}
.policy p {
    font-size: 10px;
    line-height: 13px;
    color: #5d5e5e;
    margin: 0;
}
.policy p span a {
    color: #ff5b79;
}

.modal_form_input {
    border-radius: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #ff385c;
    border-color: #ff385c;
}

.form_footer {
    font-size: 13px;
    font-weight: 400;
    color: #222;
}

.modal_form_btn {
    background: #EB4B71;
    border: 1px solid #EB4B71;
    width: 100%;
    padding: 7px;
    border-radius: 10px;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.modal_form_btn:hover {
    background: #fff;
    color: #EB4B71;
}

.form-check-label {
    color: #222;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    padding-right: 11px;
}
.form_footer_wrapper {
    bottom: -136px;
    width: 100%;
    left: 0;
}

@media only screen and (max-width: 600px){
    .modal_form_main {
        margin-top: 10px;
    }
}