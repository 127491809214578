.contact_left_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 173px 0 133px;
}
.propularity_logo img {
    width: 250px;
}
.contact_text h1 {
    text-align: center;
}
.contact_text p {
    font-size: 14px;
}
.contact_right_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.contact_form_box {
    width: 420px;
    padding: 41px;
    background: url(https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1695017994795.png);
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
}