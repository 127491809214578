.nav-main {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    position: fixed;
    width: 100vw;
    z-index: 999;
    background-color: #fff;
    top: 0;
}

.custom_ul {
    border-radius: 24px;
    padding: 5px 20px 5px 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07);
    justify-content: space-evenly;
    align-items: center;
    min-width: 281px;
    margin-left: 29% !important;
    margin-right: 19% !important;
}
.nav_ul {
    min-width: 174px;
    margin-left: 36% !important;
    margin-right: 23% !important;
}

.vertical_line {
    width: 1px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.07);
}

.dropdown-toggle::after {
    display: none;
}

.navbar_custom {
    width: 100vw;
    padding: 20px;
}

.callBack_btn_nav {
    border: 2px solid #ff385c;
    background-color: #fff;
    color: #ff385c;
    padding: 10px 14px;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    font-family: "Roboto", sans-serif;
}
.callBack_btn_nav a {
    color: #ff385c;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.primary_color {
    color: #ff385c;
}

.navbar-brand img {
    width: 150px;
}

.callBack_btn_nav:hover {
    background-color: #ff385c;
    color: #fff;
}
.callBack_btn_nav:hover a {
    color: #fff;
}

.navbar .megamenu {
    padding: 1rem 1rem 0;;
    width: 591px;
    margin: auto;
    left: 0;
    right: 0;
    box-shadow: 0px 4px 12px -5px rgba(0, 0, 0, 0.25);
    border: none;
    margin-top: 7px;
    background: #fff;
}
.mega_menu_items p {
    font-weight: 600;
    color: #ff385c;
}
.location_name:hover {
    cursor: pointer;
    color: #ff385c;
}

.nav-link {
    color: #222;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    cursor: pointer;
    display: inline-block;
}
.nav_icon {
    width: 22px;
}

.nav-link:hover {
    color: #ff385c;
}

.nav-link:focus {
    color: #ff385c;
}

.megamenu-row {
    margin-bottom: 20px;
}

.pl_0 {
    padding-left: 0;
    padding-right: 0;
}

.megamenu-row a {
    color: #222;
    text-decoration: none;
    font-size: 15px;
    margin-left: 8px;
}

.megamenu-row a:hover {
    color: #ff385c;
}

.dropdown-item {
    padding: 1rem 1rem;
    font-size: 15px;
}
.megamenu_locations a {
    font-size: 13px;
    font-weight: 500;
    color: #222;
    margin-bottom: 6px;
    margin: 0;
    transition: .3s all ease-in-out;
}
.megamenu_locations div {
    font-size: 13px;
    font-weight: 500;
    color: #222;
    margin-bottom: 6px;
    transition: .3s all ease-in-out;
}

.dropdown-menu {
    box-shadow: 0px 4px 12px -5px rgba(0, 0, 0, 0.25);
    border: none;
}

.city-icon {
    width: 30px;
}
.location_name {
    transition: .3s all ease-in-out;
}

.navbar-toggler:focus {
    box-shadow: none;
}
.mega_menu_items button {
    border: none;
    background-color: #fff;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    color: #ff385c;
}
.builder_dropdown {
    color: #ff385c;
}
.navbar-toggler {
    border: none;
    color: #222;
    font-size: 25px !important;
}

@media only screen and (max-width: 600px) {
    .navbar .megamenu {
        width: 90%;
    }

    .custom_ul {
    border-radius: 0;
    padding: 0;
    /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0); */
    align-items: flex-end;
    }

    .megamenu-row div {
        width: 45%;
    }

    .navbar-brand img {
        width: 120px;
    }
    .navbar_custom {
        padding: 10px;
    }
}

@media all and (min-width: 992px) {
    .navbar .has-megamenu {
        position: static !important;
    }
}
@media all and (max-width: 1492px) {
    .custom_ul {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media (max-width: 991px) {

    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }
}
@media (max-width: 476px) {
    .megamenu-row div {
        width: 100%;
    }
    .nav-main #main_nav {
        position: absolute;
        top: 60px;
        width: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        text-align: left;
    }
    .nav-main #main_nav ul.navbar-nav {
        align-items: flex-start;
    }
    .nav-main #main_nav ul.navbar-nav li {
        padding: 10px 15px;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,0.05);
    }
    .nav-main #main_nav ul.navbar-nav li p.nav-link.dropdown-toggle {
        padding: 0px;
    }
    .nav-main #main_nav ul.navbar-nav li .dropdown-menu {
        margin: 0px;
        padding: 5px 7px 0px;
        width: 100%;
        box-shadow: none;
    }
    .nav-main #main_nav ul.navbar-nav li .dropdown-menu .location_name {
        border-bottom: 1px solid rgba(0,0,0,0.05) !important;
        padding: 5px 12px;
        margin-bottom: 0px;
    }
}