.home_contact_right {
    padding-left: 100px;
}

.home_input {
    padding: 10px;
}

.home_select {
    align-items: center;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0px !important;
    position: relative;
    transition: 100ms;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 1px;
    min-height: 46px;
    border-radius: 10px;
    padding: 0px 60px;
    width: 100%;
    padding-left: 10px;
}

.home_select {
    min-height: 46px;
    border-radius: 10px;
}

.home_btn {
    padding: 10px;
}