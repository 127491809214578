.microlocation_container {
  padding-top: 30px;
}
.select_builder {
  font-size: 13px;
  width: 100%;
}
.select_builder .css-1fdsijx-ValueContainer, .select_builder .css-15lsz6c-indicatorContainer {
  padding: 0 3px !important;
}

.select_builder .css-13cymwt-control, .select_builder .css-t3ipsp-control {
  border-radius: 5px;
  height: 33px !important;
  min-height: 30px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1xc3v61-indicatorContainer {
  color: #ff385c !important;
}
.select_builder .css-1xc3v61-indicatorContainer {
  padding: 0 8px !important;
}
.select_builder .css-1jqq78o-placeholder {
  color: #222;
  font-weight: 500;
}
.no_filter_match {
  text-align: center;
  font-weight: 600;
  color: rgba(34, 34, 34, 0.5);
}
.css-t3ipsp-control {
  border-color: #ff385c !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07);
}

.clear_filter_btn {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 7.2px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.microlocation_projects {
  margin-top: 50px;
}
.pagination .page-item .page-link {
  background-color: #ffe5ea;
  color: #222;
}
.pagination .active>.page-link {
  background-color: #ff4466 !important;
  border-color: #ff4466;
}
.pagination .page-link:focus {
  box-shadow: none;
}

@media only screen and (max-width: 768px) {
  .select_builder .css-1jqq78o-placeholder {
    font-size: 12px;
  }
  .select_builder .css-1xc3v61-indicatorContainer {
    padding: 2px !important;
  }
  .css-1xc3v61-indicatorContainer {
    padding: 2px !important;
    width: 20px;
    margin-right: 2px;
  }
  .clear_filter_btn {
    font-size: 10px;
    line-height: 14px;
    width: 89%;
  }
  .css-13cymwt-control {
    min-height: 31px !important;
  }
  .css-t3ipsp-control {
    min-height: 31px !important;
  }
  .css-15lsz6c-indicatorContainer {
    padding: 2px !important;
    width: 20px;
    margin-right: 2px;
  }
  .css-1nmdiq5-menu {
    font-size: 10px !important;
    width: 150px !important;
  }
  .select_column {
    padding: 0 5px 0 12px;
  }
  .microlocation_projects {
    margin-top: 30px;
  }
  .select_builder {
    font-size: 12px;
    font-weight: 500;
    color: #222;
  }
}

