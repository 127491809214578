.filtered_items_main {
  margin-top: 123px;
}
.contactForm {
  background-image: linear-gradient(rgb(254, 239, 248), rgb(238, 251, 254));
  padding: 40px;
  border-radius: 14px;
}
.property_type_img {
  height: 422px;
  overflow: hidden;
}
.select_column {
  display: inline-block;
  width: 131px;
}
.css-1nmdiq5-menu {
  width: 150px !important;
  z-index: 9999 !important;
  font-size: 13px !important;
}
/* .css-1nmdiq5-menu::-webkit-scrollbar {
  width: 4px !important;
  display: none;
}

.select_builder::-webkit-scrollbar-thumb {
  background-color: red !important;
} */
.property_type_img img {
  object-fit: cover;
  height: 100% !important;
  border-radius: 14px;
  width: 100%;
}


@media only screen and (max-width: 768px) {
  .filtered_items_nav_main {
    position: sticky;
    top: 58px;
    z-index: 99;
    background: #fff;
  }
  .filtered_items_main {
    margin-top: 81px;
  }
  .filter_row {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  .select_column {
    width: 114px;
  }
  .filter_row::-webkit-scrollbar {
    display: none;
  }
}
