.fixed_bottom_home {
    padding-top: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }
  .fixed_form_btn {
    background: transparent !important;
    border: 1px solid #eb4b71 !important;
    padding: 7px 20px !important;
  }
  .fixed_form_btn a {
    color: #eb4b71;
  }
  .mid_line {
    width: 2px;
    height: 40px;
    background-color: #ffbfcb;
    left: 50%;
    transform: translateX(-50%);
  }
  .call_ico {
    color: #eb4b71;
    font-size: 17px;
    margin-right: 4px;
  }