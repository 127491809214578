.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, .75) !important;
    z-index: 99998;
}

.ReactModal__Content {
    /* align-items: center; */
    background: transparent !important;
    border: none !important;
    display: flex;
    justify-content: center;
    margin: auto;
    outline: none;
    overflow-y: auto;
    overflow-x: hidden !important;
    padding: 0 !important;
    position: absolute;
}
.download_card_icon{
    font-size: 18px;
    margin-bottom: 4px;
    margin-right: 3px;
}
.color_white {
    color: #fff;
}