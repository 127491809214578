.price_div .price_line{
  font-size: 13px;
  line-height: 23px;
  color: #9e9e9e;
}

.project_configuration {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
}
.property_head i {
  font-size: 13px;
  margin-right: 3px;
  color: #ff385c;
}
.configuration_box {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 250px;
}
.configuration_box:not(:last-child) {
  margin-right: 20px;
}
.project_icons {
  width: 40px;
  height: 40px;
}
.about_text_css {
  font-weight: 600;
  cursor: pointer;
}
.small_img_main {
  padding-left: 4px !important;
}
.project_details_link {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.5);
}
.config_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  width: 250px;
}
.view_floor_plan_img {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #00000040;
  display: flex;
  justify-content: center;
  transition: 0.3s all ease-in-out;
}
.view_img_btn,
.view_master_btn {
  width: 100%;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
}
.floor_img {
  position: relative;
}
.img_carosal {
  height: 510px;
  overflow: hidden;
  border-radius: 14px;
}
.floor_img .clickable-image {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
.floor_img .modal-dialog,
.master_plan .modal-dialog {
  min-width: 60%;
}
.floor_img .modal-body,
.master_plan .modal-body {
  text-align: center;
}
.floor_img:hover .view_floor_plan_img {
  opacity: 1;
}
.floor_img:hover .view_img_btn {
  color: #fff;
}
.master_plan:hover .view_floor_plan_img {
  opacity: 1;
}
.config_price img {
  width: 40px;
  height: 40px;
}
.config_size {
  background-color: #fffafb;
  padding: 14px;
  width: 250px;
}
.config_size p {
  margin-bottom: 0;
}
.config_price p:nth-child(1) {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
.config_price p:nth-child(2) {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.5);
}
.project_configuration::-webkit-scrollbar {
  display: none;
  transition: 0.3s ease-in-out;
  height: 8px;
}
.floor_configuration {
  display: block;
  overflow-y: hidden;
  max-width: 100%;
  white-space: nowrap;
  width: 100%;
}
.floor_plan_card {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.07);
  margin: 2px 0;
  padding: 10px;
  border-radius: 20px;
  width: 18rem;
  display: inline-block;
}
.floor_configuration::-webkit-scrollbar {
  display: none;
  transition: 0.3s ease-in-out;
  height: 8px;
  /* background-color: #fff0f1; */
}
.floor_plan_card:not(:last-child) {
  margin-right: 20px;
}
.floor_plan_card .card_body .row {
  margin-top: 14px;
}
.floor_plan_btn {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(249, 249, 249, 0.5);
  font-size: 12px;
  padding: 6px 10px;
  color: #222;
  width: auto;
  margin-left: 10px;
}
.floor_img {
  height: 184px;
  overflow: hidden;
}
.leaflet-top {
  z-index: 990;
}
.leaflet-bottom {
  opacity: 0;
}
.carousel_project .rec-carousel-item {
  width: 19rem;
}
.projects_carousel .full_carousel .rec-arrow-right {
  right: -40px;
}
.projects_carousel {
  padding-right: 0;
}
.carousel_project .rec-carousel-item:not(:last-child) {
  margin-right: 27px;
}

.img_gal_mob .carousel-control-next-icon,
.img_gal_mob .carousel-control-prev-icon {
  border-radius: 50% !important;
  background-color: #fff !important;
  background-size: 50% !important;
  transition: 0.3s all ease-in-out !important;
  position: relative;
}
.img_gal_mob .carousel-control-prev-icon:after {
  content: "\f104";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}
.img_gal_mob .carousel-control-next-icon:after {
  content: "\f105";
  position: absolute;
  color: #000;
  font-family: "FONTAWESOME";
  top: 6px;
  right: 11px;
  font-size: 14px;
}
.carousel-container:hover .carousel-control-prev,
.carousel-container:hover .carousel-control-next {
  display: flex;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
  transition: 0.3s all ease-in-out;
}

.project_highlights ul {
  padding-left: 17px;
  margin-top: 20px;
}
.project_highlights ul li {
  font-size: 15px;
  font-weight: 500;
  color: #222;
}
.floor_plan_card p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
.floor_plan_card p:nth-child(2) {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.5);
}
.highlights {
  margin-left: 17px;
}
.highlights li {
  font-size: 15px;
  color: #222;
}
.facility {
  display: inline-block;
  margin-bottom: 0;
}
.facility img {
  width: 25px;
  height: 25px;
}
.facility i {
  color: #ff385c;
}
.facility p {
  margin-left: 7px;
  margin-bottom: 0;
  display: inline-block;
  color: #222;
  font-size: 15px;
}
.map p {
  font-size: 15px;
  color: #222;
  font-weight: 500;
}
.map_box iframe {
  border-radius: 20px;
  width: 100%;
  height: 300px;
}
.master_plan {
  width: 100%;
  height: 340px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  /* padding: 0 0 0 12px; */
  position: relative;
  border: 1px solid #f9f7f7;
  margin-left: 12px;
}
.master_plan img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.calci_box {
  margin: 100px 0;
}
.no_data_p {
  margin-top: 30px;
  color: rgba(34, 34, 34, 0.5);
  font-size: 16px;
}
.brochure {
  border-radius: 5px;
  border: 1px solid #f6c0c0;
  width: fit-content;
  padding: 7px;
  background: #fffafb;
  margin-left: 10px;
  margin-top: 30px;
}
.floor_plan_scrollar {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
}
.floor_plan_scrollar::-webkit-scrollbar {
  display: none;
}
.leaflet-container {
  width: 100%;
  height: 340px;
  border-radius: 20px;
}
.thumbs {
  position: absolute;
  right: 5px;
  width: 72px;
  top: 27px;
}
@media only screen and (max-width: 768px) {
  .main-section{
    padding: 0px;
  }

  .property_head{
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
  }

  .leaflet-container {
    height: 220px;
  }
  .config_size p,
  .config_price p:nth-child(1),
  .config_price p:nth-child(2),
  .floor_plan_card p,
  .floor_plan_card p:nth-child(2),
  .project_highlights ul li,
  .facility p,
  .no_data_p,
  .map p {
    font-size: 13px;
  }
  .facility img {
    width: 17px;
    height: 17px;
  }
  .facility {
    margin-top: 10px;
  }
  .master_plan {
    padding: 0;
    height: 220px;
    width: 94%;
  }
  .master_plan img {
    height: 100%;
  }
  .view_floor_plan_img {
    width: 100%;
    opacity: 0 !important;
  }
  .project_highlights ul {
    margin-top: 10px;
  }
  .projects_carousel {
    margin-top: 0;
  }
  .calci_box {
    margin: 50px 0;
    background: #fffafb;
  }
  .img_carosal {
    height: 365px;
    border-radius: 0;
  }
  .project_details_link {
    font-size: 12px;
  }
  .fixed_bottom {
    background: #fffafb;
    padding: 6px 0 8px;
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    box-shadow: 0 0 1px rgba(34, 34, 34, 0.7);
  }
  .starting_price p:nth-child(1) {
    margin-bottom: 0;
    color: #222;
    font-weight: 600;
  }
  .starting_price p:nth-child(2) {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #ff385c;
  }
  .fixed_bottom button {
    border: none;
    border-radius: 10px;
    padding: 9px 20px;
    background: #eb4b71;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img_gal_mob {
    padding: 0 !important;
    margin-top: 10px;
  }
  .img_gal_mob .carousel-inner {
    border-radius: 0;
  }
  .img_gal_mob .carousel-inner .img_cover {
    border-radius: 0;
  }
  .brochure {
    margin-top: 10px;
  }
}
