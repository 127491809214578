.star {
  width: 20px;
}

.detail_p {
  font-size: 14px;
  color: #222;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.builder_h1 {
  margin-bottom: 5px;
}

.main_img {
  height: 378px;
  overflow: hidden;
  border-radius: 20px;
}

.img_gallery_container {
  padding: 0 200px;
}

.large_img_box {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 0px;
  z-index: 999;
}

.other_builders_top {
  margin-bottom: 60px;
}

.gallery_arrow_btn {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  transition: 0.4s all ease-in-out;
  position: absolute;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.go_back_gallery {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  transition: 0.4s all ease-in-out;
  position: sticky;
  background: transparent;
  color: #222;
  border: 1px solid #222;
  left: 173px;
  top: 48px;
}

.gallery_close_btn {
  position: absolute;
  left: 10px;
  top: 15px;
  border: none;
  background: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  padding: 6px 8px;
  border-radius: 6px;
  transition: 0.4s all ease-in-out;
}

.gallery_close_btn:hover {
  background: rgb(91, 90, 90);
}

.go_back_gallery:hover {
  background: rgb(91, 90, 90);
  color: #fff;
}

.gallery_arrow_btn:hover {
  background: rgb(91, 90, 90);
}

.next_btn {
  right: 183px;
}

.prev_btn {
  left: 183px;
}

.main_img img {
  width: 99%;
  height: 378px;
  border-radius: 20px;
}

.small_img {
  height: 178px;
  overflow: hidden;
  border-radius: 20px;
}

.small_img img {
  width: 100%;
  height: 178px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.detail_h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.detail_icon {
  width: 30px;
  height: 30px;
}

.res_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_all_text {
  font-size: 14px;
  font-weight: 600;
  color: #eb4b71;
}

.builder_overview {
  background-color: #fffafb;
  padding: 56px 28px 56px;
  border-radius: 20px;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact_form_footer {
  border-radius: 20px;
  margin-top: 30px;
  padding: 57px 17px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  background: url("../media/form_footer.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sticky_form {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.form_box {
  background-color: #fffafb;
  padding: 40px 25px;
  border-radius: 20px;
}

.about_builder {
  margin-top: 24px;
  font-size: 16px;
  transition: all 0.5s ease-in-out;
}

.pr60 {
  padding-right: 60px;
}

.read_btn {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  width: 102px;
}

.read_more_icon {
  font-size: 23px;
  margin-left: -8px;
  align-self: center;
  margin-bottom: 2px;
}

.main_section_detail {
  margin-top: 60px;
  padding-right: 100px;
}

.small_img_position {
  position: relative;
}

.view_all_img {
  background-color: #fff;
  padding: 6px;
  border-radius: 10px;
  position: absolute;
  color: #222;
  z-index: 2;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.view_all_img p {
  margin-bottom: 0;
  font-size: 14px;
}

.img_box_form {
  position: absolute;
  width: 130px;
  top: -32px;
  left: 104px;
}

.form_email {
  color: #222;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .builder_h1 {
    margin-bottom: 0;
  }

  .other_builders_top {
    margin-bottom: 30px;
  }

  .detail_p {
    font-size: 12px;
  }

  .star {
    width: 14px;
  }

  .builder_overview {
    display: flex;
    justify-content: space-between;
    height: auto;
  }

  .main_section_detail {
    margin-top: 20px;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
  }

  .detail_h4 {
    font-size: 13px;
  }

  .about_builder {
    font-size: 13px;
    margin-top: 10px;
  }

  .img_gallery_container {
    padding: 13px;
  }

  .go_back_gallery {
    left: 13px;
    top: 15px;
  }

  .go_back_main {
    width: 100%;
    position: fixed;
    top: 0;
    padding: 13px;
    background-color: white;
    z-index: 99;
  }

  .builder_overview_mob {
    padding: 28px 12px 25px;
    background-color: #fffafb;
    border-radius: 14px;
  }

  .mt30 {
    margin-top: 15px;
  }

  .mob_hide {
    display: none;
  }
}